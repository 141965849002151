<div
  [ngClass]="{
    'card': true,
    'expanded-card': isDisplaySystemAccessNotification
  }"
>
  <div
    [ngClass]="{
      'expanded-container': isDisplaySystemAccessNotification
    }"
  >
    <div
      [ngClass]="{
        'expanded-form-container': isDisplaySystemAccessNotification
      }"
    >
      <app-logo-container [src]="src" />
      <div class="stage">{{ subHeading }}</div>
      @if (!!_httpErrorService.httpError()) {
        <div class="error-container">
          <fa-icon [icon]="faCircleExclamation"></fa-icon>
          <span class="error-message">
            {{ _httpErrorService.httpError() }}
          </span>
        </div>
      }
      <ng-content></ng-content>
    </div>
    @if (isDisplaySystemAccessNotification) {
      <div class="long-diving-line"></div>
      <div class="form-container">
        <h1
          class="notofication-header"
          aria-label="System access notification"
          aria-describedby="system access information"
        >
          {{ LABEL_CONSTANT.SYSTEM_ACCESS_NOTIFICATION }}
        </h1>
        <div class="note-container">
          <div [innerHTML]="systemAccessNotification"></div>
        </div>
      </div>
    }
  </div>
</div>
